import './App.css';
import p1 from "./01.png";
import p1h from "./01_hint.jpg";
import p2 from './02.png';
import p2h from './02_hint.jpg';
import p3 from './03.png';
import p3h from './03_hint.jpg';
import p4 from './04.png';
import p4h from './04_hint.jpg';
import p5 from './05.png';
import p5h from './05_hint.jpg';
import p6 from './06.png';
import p6h from './06_hint.jpg';
import p7 from './07.png';
import p7h from './07_hint.jpg';
import p8 from './08.png';
import p8h from './08_hint.jpg';
import p9 from './09.png';
import p9h from './09_hint.jpg';
import p10 from './10.png';
import p10h from './10_hint.jpg';
import p11 from './11.png';
import p11h from './11_hint.jpg';
import p12 from './12.png';
import p12h from './12_hint.jpg';
import p13 from './13.png';
import p13h from './13_hint.jpg';
import p14 from './14.png';
import p14h from './14_hint.jpg';
import p15 from './15.png';
import p15h from './15_hint.jpg';
import p16 from './16.png';
import p16h from './16_hint.jpg';
import p17 from './17.png';
import p17h from './17_hint.jpg';
import p18 from './18.png';
import p18h from './18_hint.jpg';
import p19 from './19.png';
import p19h from './19_hint.jpg';
import p20 from './20.png';
import p20h from './20_hint.jpg';
import p21 from './21.png';
import p21h from './21_hint.jpg';

import {useState} from "react";
import Quiz from "./Quiz";
import Success from "./Success";


function App() {
  const [step, setStep] = useState(1);


  const successHandler = () => {
    setStep(step + 1);
  }

  let clue;
  let answer;
  let hint;
  switch (step) {
    case 1:
      clue = p1;
      hint = p1h;
      answer = 'piano';
      break;
    case 2:
      clue = p3;
      hint = p3h;
      answer = 'eye'
      break;
    case 3:
      clue = p9;
      hint = p9h;
      answer = 'poster'
      break;
    case 4:
      clue = p17;
      hint = p17h;
      answer = 'snowball'
      break;
    case 5:
      clue = p14;
      hint = p14h;
      answer = 'kick'
      break;
    case 6:
      clue = p4;
      hint = p4h;
      answer = 'swirl'
      break;
    case 7:
      clue = p15;
      hint = p15h;
      answer = 'toot'
      break;
    case 8:
      clue = p6;
      hint = p6h;
      answer = 'rattle'
      break;
    case 9:
      clue = p12;
      hint = p12h;
      answer = 'comfy'
      break;
    case 10:
      clue = p18;
      hint = p18h;
      answer = 'bones'
      break;
    case 11:
      clue = p16;
      hint = p16h;
      answer = 'nom'
      break;
    case 12:
      clue = p10;
      hint = p10h;
      answer = 'nana'
      break;
    case 13:
      clue = p2;
      hint = p2h;
      answer = 'bag'
      break;
    case 14:
      clue = p11;
      hint = p11h;
      answer = 'zzz'
      break;
    case 15:
      clue = p19;
      hint = p19h;
      answer = 'tiger'
      break;
    case 16:
      clue = p20;
      hint = p20h;
      answer = 'fish'
      break;
    case 17:
      clue = p8;
      hint = p8h;
      answer = 'brrr'
      break;
    case 18:
      clue = p5;
      hint = p5h;
      answer = 'wrench'
      break;
    case 19:
      clue = p7;
      hint = p7h;
      answer = 'hi-yah'
      break;
    case 20:
      clue = p13;
      hint = p13h;
      answer = 'quack'
      break;
    case 21:
      clue = p21;
      hint = p21h;
      answer = 'ko'
      break;
    case 22:
      answer = 'success';
      break;
  }

  if (answer === 'success') {
    return (
        <div className="App">
          <Success />
        </div>
    )
  } else {
    return (
        <div className="App">
          <Quiz clue={clue} hint={hint} answer={answer} success={successHandler} />
        </div>
    );
  }
}

export default App;
