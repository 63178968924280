export default function Success() {
  return (
      <>
        <h1>Congratulations!</h1>
        <h2>Happy Birthday, Joshua!</h2>

        <p>
          You can learn more about these cards:
        </p>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/Oot7XaixTSE"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </>
  )
}
